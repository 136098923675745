<template>
    <div v-resize="onResize" class="fill-height" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
            show-arrows
        >
            <v-tab
                :to="{
                    name: activeRoute('project-details'),
                    params: {
                        project: localProject,
                        ...(localQuotes ? { quotes: localQuotes } : {}),
                        clients: localClients,
                        users: localUsers,
                    },
                }"
            >
                Project Details
            </v-tab>

            <v-tab
                v-if="
                    user.permissions.includes('readProjectQuotes') &&
                        !genericProject
                "
                :disabled="!localQuotes"
                :to="{
                    name: activeRoute('project-quotes'),
                    params: {
                        project: localProject,
                        ...(localQuotes ? { projectQuotes: localQuotes } : {}),
                        clients: localClients,
                        users: localUsers,
                        settings,
                    },
                }"
            >
                Quotes
            </v-tab>
            <v-tab
                v-if="
                    user.permissions.includes('readProjectQuotes') &&
                        !genericProject
                "
                :to="{
                    name: activeRoute('project-milestones'),
                    params: {
                        project: localProject,
                        users: localUsers,
                    },
                }"
            >
                Milestones
            </v-tab>
            <v-tab
                :to="{
                    name: activeRoute('project-tasks'),
                    params: {
                        projectId: localProject.id,
                        avoidEmptyUsers: false,
                        projectCollaborators: localProject.collaborators,
                    },
                }"
            >
                Tasks
            </v-tab>
            <v-tab
                v-if="quoteToFollowUp"
                :to="{
                    name: activeRoute('project-quote-follow-up'),
                    params: {
                        id: quoteToFollowUp.id,
                        quote: quoteToFollowUp,
                        settings,
                    },
                }"
            >
                Follow Up
            </v-tab>
            <v-tab
                v-if="localProject"
                :to="{
                    name: activeRoute('project-reserves'),
                    params: {
                        projectId: localProject.id,
                    },
                }"
            >
                Stock
            </v-tab>
            <v-tab
                v-if="localProject"
                :to="{
                    name: activeRoute('project-purchases'),
                    params: {
                        projectId: localProject.id,
                    },
                }"
            >
                Purchases
            </v-tab>
            <v-tab
                v-if="localProject && this.readPartNumbers"
                :to="{
                    name: activeRoute('project-part-numbers'),
                    params: {
                        projectId: localProject.id,
                    },
                }"
            >
                PART NUMBERS
            </v-tab>
            <v-tab
                v-if="localProject"
                :to="{
                    name: activeRoute('project-work-orders'),
                    params: {
                        projectId: localProject.id,
                        project: localProject,
                    },
                }"
            >
                WO
            </v-tab>
            <v-tab
                v-if="localProject"
                :to="{
                    name: activeRoute('project-bill-of-materials'),
                    params: {
                        projectId: localProject.id,
                    },
                }"
            >
                BOM
            </v-tab>
            <v-tab
                v-if="localProject"
                :to="{
                    name: activeRoute('project-packing-lists'),
                    params: {
                        project: localProject,
                    },
                }"
            >
                PACKING LISTS
            </v-tab>
            <v-tab
                v-if="!genericProject"
                :to="{
                    name: activeRoute('b2b-forecast'),
                    params: {
                        projectId: localProject.id,
                    },
                }"
            >
                Balance to Bill Forecast
            </v-tab>
            <v-tab
                v-if="!genericProject"
                :to="{
                    name: activeRoute('project-invoices'),
                    params: {
                        project: localProject,
                    },
                }"
            >
                INVOICES
            </v-tab>
            <v-tab
                :to="{
                    name: activeRoute('project-timesheets'),
                    params: {
                        project: localProject,
                    },
                }"
            >
                TIME REPORTS
            </v-tab>
            <v-tab
                :to="{
                    name: activeRoute('project-logs'),
                    params: {
                        id: localProject.id,
                    },
                }"
            >
                LOGS
            </v-tab>
        </v-tabs>
        <v-card>
            <v-card-text
                class="overflow-y-auto"
                :style="{ height: `${height}px` }"
            >
                <router-view :key="$route.fullPath" />
            </v-card-text>
        </v-card>
        <Errors />
    </div>
</template>

<script>
import _ from 'lodash'
import API from '@/services/api'
import SocketioService from '@/services/websocket/socket.service.js'

export default {
    name: 'project',
    props: {
        id: String,
        project: Object,
        clients: Array,
        users: Array,
    },
    components: {
        Errors: () => import('@/components/Layout/Errors.vue'),
    },
    data: () => ({
        height: 0,
        localQuotes: undefined,
        localUsers: undefined,
        localClients: undefined,
        settings: undefined,
        quoteToFollowUp: false,
        errorMsg: undefined,
        localProject: undefined,
        nameRoute: undefined,
        readPartNumbers: false,
        companyId: JSON.parse(localStorage.getItem('company')),
        user: {},
    }),
    computed: {
        genericProject() {
            const routes = this.nameRoute.split('/')
            return !!routes.find(route => route == 'projects-generic')
        },
    },
    provide() {
        return {
            replaceQuoteToFollowUp: this.replaceQuoteToFollowUp,
        }
    },
    created() {
        this.nameRoute = this.$router.currentRoute.path
    },
    updated() {
        this.onResize()
    },
    async mounted() {
        try {
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            this.readPartNumbers = user.permissions.includes('readPartNumbers')
            SocketioService.connect({
                companyId: this.companyId,
            })
            const {
                data: { settings },
            } = await API.getSettings()
            this.settings = settings
            this.localUsers = _.cloneDeep(this.users)
            this.localClients = _.cloneDeep(this.clients)
            if (this.project.quotes[0]) {
                this.replaceQuoteToFollowUp({
                    id: this.project.quotes[0].split('/')[0],
                })
            }
            this.localProject = this.project
            if (user.permissions.includes('readProjectQuotes')) {
                this.localQuotes = await API.getProjectQuotes(this.project.id)
            }
        } catch (error) {
            this.errorMsg = error.message
        }
    },
    beforeDestroy() {
        SocketioService.leaveRoom(`${this.companyId}-INVOICE`)
        SocketioService.leaveRoom(`${this.companyId}-BOM`)
        SocketioService.disconnect()
    },
    methods: {
        activeRoute(tabName) {
            const routes = this.nameRoute.split('/')
            if (routes.find(route => route == 'archive-projects')) {
                return `archive-${tabName}`
            }
            if (routes.find(route => route == 'projects')) {
                return `${tabName}`
            }
            if (routes.find(route => route == 'projects-generic')) {
                return `${tabName}-generic`
            }
        },
        replaceQuoteToFollowUp(quote) {
            this.quoteToFollowUp = quote
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
            } = this.$refs
            this.height = containerHeight - tabsHeight + 18
        },
    },
}
</script>

<style></style>
